<template>
  <div>
    <svg
      v-if="data && data.children && data.children.length > 0"
      class="treemap"
      :width="width"
      :height="height"
    >
      <g ref="content" :transform="transform">
        <slot :width="contentWidth" :height="contentHeight"></slot>
      </g>
    </svg>

    <v-bottom-sheet scrollable v-model="showOthers">
      <v-card>
        <v-card-title class="justify-center">
          <v-btn text @click="showOthers = false"> Close </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list v-if="otherNodes">
            <v-list-item-group color="primary">
              <v-list-item @click="clickedOtherNode(node)" v-for="node in otherNodes" :key="node.id">
                <v-list-item-content>
                  <v-list-item-title> {{ node.name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="node.reservedQuantity">
                    {{$vuetify.lang.t('$vuetify.inventory.reserved')}}:  {{ node.reservedQuantity | formatInteger }} {{ node.measureWeight }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                  <v-list-item-action-text>
                    {{ node.value | formatInteger }} {{ node.measureWeight }}
                  </v-list-item-action-text>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import * as d3 from "d3";
import Region from "../mixins/Region";
import cloneDeep from "lodash/cloneDeep";

export default {
  mixins: [Region],
  props: {
    data: {
      type: Object,
      required: true,
    },
    nameFn: {
      type: Function,
      default: (node) => node.name,
    },
    labelFn: {
      type: Function,
      required: false,
    },
    valueFn: {
      type: Function,
      required: false,
    },
    colorFn: {
      type: Function,
      required: false,
    },
    fontSize: {
      type: Number,
      required: false,
      default: 15,
    },
  },
  data: () => ({
    showOthers: false
  }),
  computed: {
    treemap() {
      return d3
        .treemap()
        .tile(d3.treemapResquarify)
        .size([this.contentWidth, this.contentHeight])
        .round(true)
        .paddingInner(1);
    },
    root() {
      //make a copy of the data
      const cloneData = cloneDeep(this.data);
      this.distributePercentage(cloneData);
      this.transformTree(cloneData);

      const root = d3.hierarchy(cloneData);
      root.eachBefore((d) => {
        // d.data.id = (d.parent ? d.parent.data.id + "." : "") + this.nameFn(d.data)
        d.data.id =
          (d.parent ? d.parent.data.id + "." : "") + this.nameFn(d.data);
      });
      return root;
    },
  },
  watch: {
    valueFn(val) {
      this.treemap(this.root.sum(val));
      const cell = d3.select(this.$refs.content).selectAll("g");
      cell
        .transition()
        .attr("transform", (d) => `translate(${d.x0},${d.y0})`)
        .select("rect")
        .attr("width", (d) => d.x1 - d.x0)
        .attr("height", (d) => d.y1 - d.y0);
    },
  },
  methods: {
    update() {
      const vueComponent = this;
      const { data, valueFn, colorFn, root } = this;

      if (!data || !data.children || data.children.length === 0) return;
      root
        .sum(valueFn)
        .sort((a, b) => b.height - a.height || b.value - a.value);
      this.treemap(root);
      const cell = d3
        .select(this.$refs.content)
        .selectAll("g")
        .data(root.leaves())
        // .data(root.children)
        .enter()
        .append("g")
        .attr("transform", (d) => `translate(${d.x0},${d.y0})`);

      cell
        .append("rect")
        .attr("id", (d) => d.data.id)
        .attr("isOthers", (d) => d.data.isOthers)
        .attr("compositionIds", (d) => d.data.compositionIds)
        .attr("isleaf", (d) => d.data.isLeaf)
        .attr("width", (d) => d.x1 - d.x0)
        .attr("height", (d) => d.y1 - d.y0)
        .attr("fill", (d) => colorFn(d.isLeaf ? d.data.id : d.parent.data.id));

      cell
        .append("clipPath")
        .attr("id", (d) => "clip-" + d.data.id)
        .append("use")
        .attr("xlink:href", (d) => "#" + d.data.id);

      this.appendText(cell);

      // const format = d3.format(",d")
      cell.append("title").text((d) => this.labelFn(d.data));

      cell.selectAll("rect", "text").on("click", function () {
        const id = this.getAttribute("id");
        const isLeaf = this.getAttribute("isleaf");
        const isOthers = this.getAttribute("isOthers");
        if (id && isLeaf && !isOthers) {
          vueComponent.$emit("click", id);
        } else if (!isLeaf) {
          vueComponent.$emit("drillDown", id);
        } else {
          const ids = this.getAttribute("compositionIds")
            .split(",")
            .map((id) => parseInt(id));
          console.log(ids);
          vueComponent.showOthers = true;
          vueComponent.otherNodes = vueComponent.findNodesById(
            vueComponent.data,
            ids
          );
        }
      });
    },
    distributePercentage(node) {
      if (!node) return;

      let children = node.children || [];
      let sum = 0;

      for (let child of children) {
        this.distributePercentage(child);
        sum += child.value ? child.value : 0;
      }

      if (!sum) {
        node.percentage = 100;
      } else {
        for (let child of children) {
          child.percentage = (child.value / sum) * 100;
        }
        node.percentage = 100;
      }

      node.value = sum + node.value;
    },
    /**
     * Traverse tree and group leaf nodes and group the bottom 15% into a single node called "Others"
     */
    transformTree(node) {
      if (!node) return;

      let children = node.children || [];

      if (children.length === 0) return;

      let others = [];
      let sum = 0;
      let othersPercentage = 0;

      const maxPercentage = 15;

      for (let i = 0; i < children.length; i++) {
        let child = children[i];
        this.transformTree(child);

        if (child.percentage < maxPercentage) {
          others.push(child);
          sum += child.value;
          othersPercentage += child.percentage;
        }
      }

      // remove the largest node from the others list if exceeded maxPercentage
      if (othersPercentage > maxPercentage) {
        others.sort((a, b) => b.percentage - a.percentage);
        while (othersPercentage > maxPercentage && others.length > 1) {
          let largest = others.shift();
          sum -= largest.value;
          othersPercentage -= largest.percentage;
        }
      }

      if (others.length > 0) {
        children = children.filter((child) => !others.includes(child));
        children.push({
          id: "others",
          isOthers: true,
          isLeaf: true,
          name: others.map((o) => o.name).join("\n"),
          value: sum,
          percentage: othersPercentage,
          compositionIds: others.map((o) => o.id).join(","),
          children: [],
        });
      }

      node.children = children;
    },
    findNodesById(root, ids) {
      if (!root) return [];

      const results = [];

      const stack = [root];
      while (stack.length > 0) {
        const node = stack.pop();

        if (ids.includes(node.id)) {
          results.push(node);
        }

        const children = node.children || [];
        stack.push(...children);
      }

      return results;
    },
    appendText(cell) {
      cell
        .append("text")
        .attr("clip-path", (d) => "url(#clip-" + d.data.id + ")")
        .style('pointer-events', 'none') // explicity allow pointer click event.  This is needed.
        .selectAll("tspan")
        .data((d) => this.labelFn(d.data))
        .join("tspan")
        .attr("x", 3)
        .attr(
          "y",
          (d, i, D) => `${(i === D.length - 1) * 0.3 + 1.1 + i * 0.9}em`
        )
        .attr("fill-opacity", (d, i, D) => (i === D.length - 1 ? 0.7 : null))
        .text((d) => d)
        .attr("font-size", `${this.fontSize}px`);
      // .attr("fill", "white");
    },
    clickedOtherNode(node){
      this.$emit('click', node.id)
      this.showOthers = false;
    }
  },
  mounted() {
    console.log("mounted treemap... ");
    this.update();
  },
};
</script>